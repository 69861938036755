var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-network-switch full-width"},[(!_vm.shouldFilter && _vm.hasNetworks)?_c('v-row',{staticClass:"align-end justify-center justify-sm-space-between pa-0"},[_c('div',{staticClass:"align-center align-sm-end justify-center pr-sm-3 pb-sm-3 order-sm-2 mt-10 mt-sm-0"},[_c('v-btn-toggle',{attrs:{"mandatory":"","active-class":"buttonToggleDark white--text alig-end"},model:{value:(_vm.toggleType),callback:function ($$v) {_vm.toggleType=$$v},expression:"toggleType"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v("Main")]),_c('v-btn',{attrs:{"small":""}},[_vm._v("Test")]),_c('v-btn',{attrs:{"small":""}},[_vm._v("All")])],1)],1),_c('v-col',{staticClass:"order-sm-1",attrs:{"cols":"12","sm":"7"}},[_c('mew-search',{attrs:{"placeholder":"Find Network","value":_vm.searchInput},on:{"input":_vm.setSearch}})],1)],1):_vm._e(),(_vm.showEmptySearch || _vm.shouldFilter)?_c('app-user-msg-block',{staticClass:"mt-5",attrs:{"message":_vm.emptySearchMes,"is-alert":_vm.shouldFilter}}):_vm._e(),_c('v-radio-group',{class:_vm.networks.length > 10 ? 'network-container' : '',model:{value:(_vm.networkSelected),callback:function ($$v) {_vm.networkSelected=$$v},expression:"networkSelected"}},_vm._l((_vm.networks),function(network,i){return _c('v-container',{key:network.name + network.chainID,class:[
        { 'network-border-first': i === 0 },
        { 'network-border-last': i + 1 === _vm.networks.length },
        'py-4 px-5 network-border'
      ]},[_c('v-row',{staticClass:"pa-0 mew-body align-center justify-start"},[_c('mew-token-container',{attrs:{"img":network.icon,"size":"24px"}}),_c('div',{staticClass:"textDark--text Capitalize pl-3"},[_vm._v(" "+_vm._s(network.name)+" ")]),_c('div',{staticClass:"px-2 textLight--text"},[_vm._v("-")]),_c('div',{class:[
            'textLight--text',
            _vm.$vuetify.breakpoint.smAndDown ? 'network-long-name' : ''
          ]},[_vm._v(" "+_vm._s(network.name_long)+" ")]),_c('v-spacer'),_c('v-radio',{class:['py-2 mb-0'],attrs:{"value":network.name,"disabled":_vm.networkLoading}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }